import React from "react";
import styled from "styled-components";
import translate from "../../providers/i18n/translate";
import { ThemeContext } from "../../providers/theme";
import { useIntl } from "react-intl";
import { TextField } from "@mui/material";
import BasicEditor from "../textEditors/basicEditor";
import { ColorPicker, useColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

/* Styled components */
const Root = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  max-height: 90vh;
  overflow-y: auto;
  @media (min-width: 1024px) {
    width: 600px;
  }
`;

const ContentEditor = styled.div`
  position: relative;
  width: 50%;
  height: 100%;
`;

const Title = styled.h2`
  width: 100%;
  border-bottom: 1px solid
    ${(props) => (props.borderColor ? props.borderColor : "#000")};
  text-transform: uppercase;
  color: ${(props) => (props.color ? props.color : "#000")};
  margin: 0 0 10px 0;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 5px;
  background-color: ${(props) => (props.bg ? props.bg : "#7A7A7A")};
  color: ${(props) => (props.color ? props.color : "#FFF")};
  border: none;
  outline: none;
  cursor: pointer;
  margin: 10px 0;
  transition: all 300ms ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
`;

const InputData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  @media (min-width: 1024px) {
    width: ${(props) => (props.width ? props.width : "inherit")};
  }
`;

const StyledTextfield = styled(TextField)`
  width: 100%;
  @media (min-width: 1024px) {
    width: ${(props) => (props.width ? props.width : "inherit")};
  }
`;

const InputGroup = styled.div`
  position: relative;
  width: 100%;
  max-height: 350px;
  display: flex;
  gap: 20px;
`
const InputLabels = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  gap: 20px;
`
const InputLabel = styled.div`
  flex-shrink: 0;
  position: relative;
  width: 50%;
`

const InputError = styled.span`
  width: fit-content;
  height: ${(props) => (props.show ? "auto" : 0)};
  overflow: hidden;
  color: ${(props) => props.theme.palette.error};
  padding: ${(props) => (props.show ? "8px 10px" : 0)};
  opacity: ${(props) => (props.show ? 1 : 0)};
  border: ${(props) => props.theme.palette.error};
  background: ${(props) => `${props.theme.palette.error}10`};
  border-radius: 8px;
  margin-bottom: 10px;
  font-size: 0.875rem;
  transition: all 300ms ease-in-out;
`;

const HoverCardsSectorsContentMedia = ({ id, editData, handleMediaData, handleClose }) => {
  const theme = React.useContext(ThemeContext);

  const intl = useIntl();

  const onChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    console.log("e.target",value);
    setData({
      ...data,
      [name]: value,
    });
  };

  const [color, setColor] = useColor("hex", editData?.backgroundColor ?? '#fff0');

  const [editorLoaded, setEditorLoaded] = React.useState(false);

  const [data, setData] = React.useState(
    editData
      ? {
        ...editData
      }
      : {
        title: "",
        titleColor: "#000",
        text: "",
        url: "",
        newTab: false,
      }
  );

    const handleTextEditor = (e, dataeditor) => {
        setData({
            ...data,
            text: dataeditor,
        });
    };

    const handleChangeCheck = (e) => {
      const { name, checked } = e.target;
      setData({
          ...data,
          [name]: checked,
      });
  };

  const submit = () => {
    let formattedData = data;
    formattedData['backgroundColor'] = color.hex;
    handleMediaData(id, {
      ...formattedData,
      positionHData: data?.positionHData?.value,
      positionVData: data?.positionVData?.value
    });
    handleClose();
  };

  React.useEffect(() => {
      setEditorLoaded(true);
  }, []);

  return (
    <Root>
      <Title>
        {editData ? translate("edit content") : translate("add content")}
      </Title>
      <InputGroup width="100%">
        <StyledTextfield
          id="title"
          name="title"
          onChange={onChange}
          value={data?.title}
          label={intl.formatMessage({ id: "title" })}
          variant="outlined"
          width="50%"
          margin="normal"
          size="small"
        />
        <input 
          id="titleColor" 
          name="titleColor"
          type="color" 
          value={data.titleColor} 
          onChange={onChange}           
          label={intl.formatMessage({ id: "titleColor" })}
          variant="outlined"
          margin="normal"
          size="small"
          style={{marginTop: '20px', height: '35px'}}
        />
      </InputGroup>
      <InputGroup width="100%">
        <StyledTextfield
          id="url"
          name="url"
          onChange={onChange}
          value={data?.url}
          label={intl.formatMessage({ id: "url" })}
          variant="outlined"
          width="50%"
          margin="normal"
          size="small"
        />
        <FormControlLabel
            control={
                  <Checkbox
                  name="newTab"
                  checked={data?.newTab}
                  onChange={handleChangeCheck}
                  inputProps={{ "aria-label": "new tab" }}
                  />
}
                  label={intl.formatMessage({ id: "newTab" })}
        />
      </InputGroup>
      <InputLabels>
        <InputLabel>{intl.formatMessage({ id: "text" })}</InputLabel>
        <InputLabel>{intl.formatMessage({ id: "background color" })}</InputLabel>
      </InputLabels>
      <InputGroup>
        <ContentEditor>
          <BasicEditor
          name="text"
          value={data.text}
          onChange={handleTextEditor}
          editorLoaded={editorLoaded}
          minContentHeight="250px"
          placeholder={intl.formatMessage({ id: "insert text here..." })}
        />
          </ContentEditor>
        <ColorPicker width={250} height={200} color={color} onChange={setColor} alpha hideRGB hideHSV dark/>
      </InputGroup>

      <Button
        onClick={submit}
        bg={theme.palette.accent}
        color={theme.palette.base}
      >
        {theme.icons.check}
      </Button>
    </Root>
  );
};

export default HoverCardsSectorsContentMedia;
